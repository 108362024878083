import React from 'react';
import '../index.css';
import LeaderboardTable from "./LeaderboardTable";
import {
    useFetchAllUserPicksQuery,
    useFetchAllUserStrikesQuery,
    useFetchCurrentWeekNumQuery,
    useFetchGamesQuery, useFetchTeamsQuery,
    useFetchUsersQuery
} from "../store";
import _ from 'underscore';

function LeaderboardPage({currentUser}) {

    const {data:games, isFetching:isFetchingGames, error: errorGames} = useFetchGamesQuery();
    const {data:users, isFetching:isFetchingUsers, error: errorUsers} = useFetchUsersQuery();
    const {data:allStrikes, isFetching: isFetchingAllStrikes, error: errorAllStrikes} = useFetchAllUserStrikesQuery();
    const {data:allPicks, isFetching: isFetchingAllPicks, error: errorAllPicks} = useFetchAllUserPicksQuery();
    const {data:currentWeekNum, isFetching: isFetchingCurrWeek, error: errorCurrWeek} = useFetchCurrentWeekNumQuery();
    const {data:teams, isFetching:isFetchingTeam, error: errorTeams} = useFetchTeamsQuery();


    if (isFetchingGames || isFetchingUsers || isFetchingAllStrikes || isFetchingAllPicks || isFetchingCurrWeek || isFetchingTeam) {
        return <div>Fetching Leaderboard</div>
    } else if (errorGames || errorUsers || errorAllStrikes || errorAllPicks || errorCurrWeek || errorTeams) {
        return <div>Error fetching Leaderboard</div>
    } else {

        const filteredGames = games.filter(game => game.weekNumber < currentWeekNum.weekNum);
        const gamesByWeekNum = _.groupBy(filteredGames, (game) => game.weekNumber);

        const winningTeamsByWeek = {}
        for (const weekNum in gamesByWeekNum) {
            const winningTeams = [];
            for (const game of gamesByWeekNum[weekNum]) {
                if ((game.homeTeamScore != null && game.homeTeamScore >= 0) && (game.awayTeamScore != null && game.awayTeamScore >= 0)) {
                    if (game.homeTeamScore > game.awayTeamScore) {
                        winningTeams.push(game.homeTeam);
                    } else if (game.awayTeamScore > game.homeTeamScore) {
                        winningTeams.push(game.awayTeam);
                    } else if (game.awayTeamScore === game.homeTeamScore) {
                        winningTeams.push(game.homeTeam);
                        winningTeams.push(game.awayTeam);
                    } else {
                        console.log("Couldn't get winner from game: ", game)
                    }
                }
            }
            winningTeamsByWeek[weekNum] = winningTeams;
        }

        const teamsByWeek = {};
        for (const week in winningTeamsByWeek) {
            const teamsRes = {};
            for (const team of teams) {
                const {teamId, logoPath} = team;
                let teamWon = false;
                if (winningTeamsByWeek[week].includes(teamId)) {
                    teamWon = true
                }
                teamsRes[teamId] = {
                    logoPath: logoPath,
                    teamWon: teamWon
                }
            }
            teamsByWeek[week] = teamsRes;
        }

        const leaderboardRes = [];
        let playersRemainingTotal = 0;
        for (const user of users) {
            const userStrike = allStrikes.filter(strikes => strikes.email === user.email)[0];

            const userEmail = user.email;
            const userId = user._id;
            const teamName = user.leagueName;
            const firstName = user.firstName;
            const lastName = user.lastName;
            const hasInsurance = user.hasInsurance;
            const strikes = userStrike.num_strikes;
            const strikeStatus = userStrike.state || 'N/A';
            const userPicks = allPicks.filter(pick => pick.username === user.email).filter(pick => pick.weekNumber < currentWeekNum.weekNum);
            const transformedPicks = userPicks.map(pick => {
                return {
                    weekNum: pick.weekNumber,
                    teamId: pick.teamId
                }
            });

            const res = {
                userEmail,
                userId,
                firstName,
                lastName,
                teamName,
                strikes,
                strikeStatus,
                hasInsurance,
                picks: transformedPicks
            }
            leaderboardRes.push(res);

            if (strikes < 2) {
                playersRemainingTotal++;
            }
        }

        leaderboardRes.sort((a, b) => {
            if (a.strikes !== b.strikes) {
                return a.strikes - b.strikes;
            }
            return a.firstName.localeCompare(b.firstName);
        });

        const leaderPartitioned = _.groupBy(leaderboardRes, (user) => user.strikes);
        //console.log("Partitioned: ", leaderPartitioned);

        const columnRes = [];
        columnRes.push({
            Header: 'Team',
            accessor: 'teamName',
            sticky: 'left',
            Footer: 'Players Remaining'
        });


        let loopWeek = 0;
        if (currentWeekNum.weekNum === 1) {
            loopWeek = 1
        } else if(currentWeekNum.weekNum > 1) {
            loopWeek = currentWeekNum.weekNum -1;
        }

        for (let i = loopWeek; i >= 1; i--) {
            const col = {
                Header: 'Week ' + i,
                accessor: 'weekNum' + i,
                maxWidth: 75
            }
            if (i === loopWeek) {
                col['Footer'] = playersRemainingTotal - 1;
            }
            columnRes.push(col);
        }

        const leaderboardKeys2 = Object.keys(leaderPartitioned).map(key => parseInt(key));
        const playerData = [];
        for (let i = 0; i < leaderboardKeys2.length; i++) {
            if (i === 0) {
                const alive = {
                    id: 'id-alive',
                    teamName: <div className='bg-success text-white'>ALIVE</div>
                }
                playerData.push(alive)
            } else if (i === 1) {
                const strike = {
                    id: 'id-strike',
                    teamName: <div className='bg-warning text-dark'>One Strike</div>
                }
                playerData.push(strike)
            } else {
                const eliminated = {
                    id: 'id-alive',
                    teamName: <div className='bg-danger text-white'>Eliminated</div>
                }
                playerData.push(eliminated)
            }

            for (const userFirst of leaderPartitioned[i]) {

                let currPlayerStyle = '';
                if (userFirst.userEmail === currentUser.email) {
                    currPlayerStyle = 'bg-secondary text-white'
                }

                const player = {
                    id: 'id-' + userFirst.teamName,
                    userProps: "foobarProp",
                    teamName: <div className={currPlayerStyle}>
                        <span className='fw-bold'>{userFirst.teamName}</span>
                        <br/>
                        {userFirst.firstName + ' ' + userFirst.lastName}
                    </div>
                }

                if (userFirst.picks) {
                    for (const pick of userFirst.picks) {
                        const logoPath = teamsByWeek[pick.weekNum][pick.teamId].logoPath
                        let redLogo;

                        // TODO add empty picks here too
                        if (pick.weekNum === 1 && userFirst.hasInsurance) {
                            redLogo = 'alert alert-info p-0'
                        } else if (teamsByWeek[pick.weekNum][pick.teamId].teamWon) {
                            redLogo = 'alert alert-danger p-0'
                        }

                        player['weekNum' + pick.weekNum] =(
                            <div className={redLogo}>
                            <img className='mx-auto d-block' alt='teamLogo' width='50' height='50'
                                 src={`data:image/svg+xml;utf8,${encodeURIComponent(logoPath)}`}/>
                        </div>);
                    }
                }
                playerData.push(player);
            }
        }

        //console.log("playerRes: ", playerData);


        return <LeaderboardTable columns={columnRes} data={playerData}/>
    }

}

export default LeaderboardPage;